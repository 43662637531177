<template>
  <DropdownMenuPortal v-if="!context.isMobile">
    <Transition
      enter-active-class="transition-opacity duration-100 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <component
        :is="!context.isNested ? DropdownMenuContent : DropdownMenuSubContent"
        :align="props.align ?? !context.isNested ? 'end' : 'start'"
        :align-offset="!context.isNested ? 0 : -8"
        :side-offset="8"
        :collision-padding="10"
        hide-when-detached
        prioritize-position
        class="flex flex-col rounded-md bg-gray-900 p-2 text-white shadow-lg z-50"
      >
        <slot />
      </component>
    </Transition>
  </DropdownMenuPortal>
  <DrawerPortal v-else>
    <DrawerOverlay class="fixed bg-black/40 inset-0 z-20" />

    <DrawerContent
      class="fixed bottom-0 left-0 right-0 z-50 flex max-h-[80%] flex-col rounded-t-md bg-gray-900 text-white"
    >
      <div
        :class="[
          'flex grow flex-col overflow-hidden px-3 py-4',
          props.scrollable && 'overflow-y-auto',
        ]"
      >
        <slot />
      </div>
    </DrawerContent>
  </DrawerPortal>
</template>

<script setup lang="ts">
import {
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from "radix-vue";
import { DrawerContent, DrawerOverlay, DrawerPortal } from "vaul-vue";

import { inject } from "./context";

const context = inject();

const props = defineProps<{
  align?: "start" | "center" | "end";
  /** Setting `overflow: auto` makes drawer slides wonky on touch emulation */
  scrollable?: boolean;
}>();
</script>
